export default {
  data: function() {
    return {
      pageNavs: [
        {
          id: '01',
          name: '購入履歴',
          path: '/event/history/buy',
          routeName: 'EventHistoryBuy',
          includes: true,
        },
        {
          id: '02',
          name: '支払履歴',
          path: '/event/history/use',
          routeName: 'EventHistoryUse',
          includes: true,
        },
      ],
    };
  },
};
